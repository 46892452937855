<template>
    <svg
        width="22"
        height="14"
        viewBox="0 0 22 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.66667 14H14.5C15.1417 14 15.6667 13.475 15.6667 12.8333C15.6667 12.1917 15.1417 11.6667 14.5 11.6667H1.66667C1.025 11.6667 0.5 12.1917 0.5 12.8333C0.5 13.475 1.025 14 1.66667 14ZM1.66667 8.16667H11C11.6417 8.16667 12.1667 7.64167 12.1667 7C12.1667 6.35833 11.6417 5.83333 11 5.83333H1.66667C1.025 5.83333 0.5 6.35833 0.5 7C0.5 7.64167 1.025 8.16667 1.66667 8.16667ZM0.5 1.16667C0.5 1.80833 1.025 2.33333 1.66667 2.33333H14.5C15.1417 2.33333 15.6667 1.80833 15.6667 1.16667C15.6667 0.525 15.1417 0 14.5 0H1.66667C1.025 0 0.5 0.525 0.5 1.16667ZM20.6833 10.36L17.3233 7L20.6833 3.64C20.7913 3.53199 20.877 3.40376 20.9355 3.26263C20.9939 3.12151 21.024 2.97025 21.024 2.8175C21.024 2.66475 20.9939 2.51349 20.9355 2.37237C20.877 2.23124 20.7913 2.10301 20.6833 1.995C20.5753 1.88699 20.4471 1.80131 20.306 1.74285C20.1648 1.6844 20.0136 1.65431 19.8608 1.65431C19.7081 1.65431 19.5568 1.6844 19.4157 1.74285C19.2746 1.80131 19.1463 1.88699 19.0383 1.995L14.85 6.18333C14.7418 6.29127 14.656 6.41947 14.5975 6.56061C14.5389 6.70174 14.5088 6.85304 14.5088 7.00583C14.5088 7.15863 14.5389 7.30993 14.5975 7.45106C14.656 7.5922 14.7418 7.7204 14.85 7.82833L19.0383 12.0167C19.4933 12.4717 20.2283 12.4717 20.6833 12.0167C21.1267 11.5617 21.1383 10.815 20.6833 10.36Z"
            fill="#E91B23"
        />
    </svg>
</template>
