<script setup>
import { ref } from "vue";
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import Button from "@/Components/Button.vue";
import { Link } from "@inertiajs/vue3";
import Hamburger from "@/Components/Icons/Hamburger.vue";

const isMobileMenuOpen = ref(false);

const toggleMobileMenu = () => {
    isMobileMenuOpen.value = !isMobileMenuOpen.value;
};

const menuItems = [
    { name: "Accueil", href: "home" },
    //{ name: "Comment ça marche ?", href: "dashboard" },
   // { name: "Soirée & Événement", href: "dashboard" },
    {
        name: "Boutique",
        href: "https://plaisironline.shop",
        external: true,
        target: "_blank",
    },
    { name: "Blog", href: "blog.index" },
];
</script>

<template>
    <header>
        <nav class="bg-white py-4 h-20 flex items-center">
            <div
                class="container mx-auto flex justify-between items-center px-4 lg:px-0"
            >
                <div class="flex items-center">
                    <Link href="/">
                        <ApplicationLogo />
                    </Link>
                </div>

                <div
                    class="hidden lg:flex space-x-8 lg:max-w-screen-sm lg:w-full lg:justify-center"
                >
                    <component
                        :is="item.target ? 'a' : Link"
                        v-for="item in menuItems"
                        :key="item.name"
                        :href="item.external ? item.href : route(item.href)"
                        class="menu-item"
                        :target="item.target"
                    >
                        {{ item.name }}
                    </component>
                </div>

                <div class="flex items-center">
                    <Button
                        as="link"
                        :href="route('login')"
                        class="!rounded-full h-auto lg:block hidden"
                    >
                        {{
                            $page.props.auth.user
                                ? "Mon compte"
                                : "Se connecter"
                        }}
                    </Button>

                    <button @click="toggleMobileMenu" class="ml-4 lg:hidden">
                        <Hamburger />
                    </button>
                </div>
            </div>
        </nav>

        <div v-if="isMobileMenuOpen" class="lg:hidden">
            <div
                class="fixed inset-0 bg-black bg-opacity-50 z-40"
                @click="toggleMobileMenu"
            ></div>
            <div
                class="fixed inset-y-0 right-0 max-w-xs w-full bg-white shadow-xl z-50 p-6"
            >
                <div class="flex items-center justify-between mb-8">
                    <h2 class="text-xl font-bold">Menu</h2>
                    <button
                        @click="toggleMobileMenu"
                        class="text-gray-500 hover:text-gray-700"
                    >
                        <svg
                            class="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <div class="flex flex-col space-y-4">
                    <component
                        :is="item.target ? 'a' : Link"
                        v-for="item in menuItems"
                        :key="item.name"
                        :href="item.external ? item.href : route(item.href)"
                        :target="item.target"
                        class="menu-item block py-2"
                        @click="toggleMobileMenu"
                    >
                        {{ item.name }}
                    </component>

                    <Button
                        as="link"
                        :href="route('login')"
                        class="!rounded-full h-auto flex items-center justify-center lg:hidden"
                    >
                        {{
                            $page.props.auth.user
                                ? "Mon compte"
                                : "Se connecter"
                        }}
                    </Button>
                </div>
            </div>
        </div>
    </header>
</template>
